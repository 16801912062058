import React, { useEffect, useRef } from "react";
import { cubicBezier } from "framer-motion";
import { ReactComponent as SVG } from "../img/points.svg";

const maxDistance = 50;
const ease = cubicBezier(0, 0, 1, 1);

export default function Points({ className }) {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current?.getElementsByTagName) {
      return;
    }

    const tags = Array.from(ref.current.getElementsByTagName("circle"));
    const children = tags.map((el) => ({
      box: el.getBoundingClientRect(),
      lastDist: -1,
      el,
    }));

    function check(up = false, evt = {}, radius = maxDistance) {
      let { clientX, clientY } = evt;
      for (const child of children) {
        const {
          el,
          box: { x, y },
        } = child;
        const a = clientX - x;
        const b = clientY - y;
        const dist = Math.sqrt(a * a + b * b);
        if (up && dist <= radius) {
          el.style.opacity = 0;
          continue;
        }

        el.style.opacity = 1 - Math.max(0, ease(dist / radius));
        if (
          child.lastDist >= 0 &&
          child.lastDist < dist &&
          !el.classList.contains("bye")
        ) {
          el.classList.add("bye");
        } else if (child.lastDist > dist && el.classList.contains("bye")) {
          el.classList.remove("bye");
        }
        child.lastDist = Math.max(dist, 0);
      }
    }

    const start = check.bind(0, false);
    const stop = check.bind(0, true);

    const svgRect = ref.current.getBoundingClientRect();
    const stepSize = svgRect.width / 150;
    let x = svgRect.x - svgRect.width * 0.25;

    setTimeout(() => {
      const intervalId = setInterval(() => {
        if (x <= svgRect.x + svgRect.width * 1.5) {
          start(
            { clientX: x, clientY: svgRect.y + svgRect.height * 0.5 },
            maxDistance * 2
          );
          x += stepSize;
        } else {
          clearInterval(intervalId);
        }
      }, 10);
    }, 500); // delay of half a second

    document.addEventListener("mousemove", start);
    document.addEventListener("mouseout", stop);
    document.addEventListener("pointermove", start);
    document.addEventListener("pointerout", stop);

    return () => {
      document.removeEventListener("mousemove", start);
      document.removeEventListener("mouseout", stop);
      document.removeEventListener("pointermove", start);
      document.removeEventListener("pointerout", stop);
    };
  }, [ref]);

  return <SVG className={className} ref={ref} />;
}
