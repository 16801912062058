import React, { useState, useEffect } from "react";

const CountdownTimer = () => {
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    const countdownDate = new Date("2024-10-01");
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimeRemaining("😈");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const milliseconds = distance % 100;

        const formattedTime = `${padZero(days)}:${padZero(hours)}:${padZero(
          minutes
        )}:${padZero(seconds)}:${padZero(milliseconds, 2)}`;
        setTimeRemaining(formattedTime);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const padZero = (value, length = 2) => {
    return String(value).padStart(length, "0");
  };

  return <div className="timer">{timeRemaining}</div>;
};

export default CountdownTimer;
