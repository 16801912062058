import React, { useState, useRef, useEffect } from "react";
import Lottie from "react-lottie";
import logo from "./img/logo.png";
import dribbbleLottie from "./img/dribbble.json";
import twitterLottie from "./img/twitter.json";
import mailLottie from "./img/mail.json";
import { motion } from "framer-motion";
import { ReactComponent as Logo } from "./img/textlogo.svg";
import Points from "./components/Points";
import CountdownTimer from "./components/Timer";
import "./App.css";

function App() {
  const [stopped, setStopped] = useState({
    dribbble: true,
    twitter: true,
    mail: true,
  });

  const dribbbleLottieRef = useRef(null);
  const twitterLottieRef = useRef(null);
  const mailLottieRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const dribbbleProps = {
    loop: false,
    autoplay: false,
    animationData: dribbbleLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const twitterProps = {
    loop: false,
    autoplay: false,
    animationData: twitterLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const mailProps = {
    loop: false,
    autoplay: false,
    animationData: mailLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleIconMouseEnter = (icon) => {
    if (icon === "dribbble") dribbbleLottieRef.current.setDirection(1);
    if (icon === "twitter") twitterLottieRef.current.setDirection(1);
    if (icon === "mail") mailLottieRef.current.setDirection(1);

    setStopped((prevState) => ({
      ...prevState,
      [icon]: false,
    }));
  };

  const handleIconMouseLeave = (icon) => {
    if (icon === "dribbble") dribbbleLottieRef.current.setDirection(-1);
    if (icon === "twitter") twitterLottieRef.current.setDirection(-1);
    if (icon === "mail") mailLottieRef.current.setDirection(-1);

    setStopped((prevState) => ({
      ...prevState,
      [icon]: true,
    }));
  };

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("load", handleLoad);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("load", handleLoad);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`App ${isLoading ? "loading" : ""}`}>
      {isLoading}
      <div className="hero-side" style={{ minHeight: `${viewportHeight}px` }}>
        <motion.div
          className="overlay"
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ type: "tween", duration: 1 }}
        />
        <motion.div
          className="hero-gradient absoluteCenter"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "tween", duration: 5 }}
        ></motion.div>
        <div className="blueprint-background">
          <motion.div
            className="background-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "tween", duration: 3 }}
          ></motion.div>
        </div>

        <div className="top">
          <div className="logo">
            <img className="logo-image" src={logo} draggable="false" />
          </div>
          <a className="link" target="_blank" href="http://bit.ly/2kxVv3W">
            <div className="button">Join Discord</div>
          </a>
        </div>

        <div className="logo-container">
          <Logo />
          <Points />
        </div>

        <div className="bottom">
          <div className="icons">
            <a
              href="http://bit.ly/2kxVaOI"
              target="_blank"
              className="icon-container twitter"
              onMouseEnter={() => handleIconMouseEnter("twitter")}
              onMouseLeave={() => handleIconMouseLeave("twitter")}
            >
              <Lottie
                options={twitterProps}
                isClickToPauseDisabled
                isStopped={stopped.twitter}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: () =>
                      setStopped((prevState) => ({
                        ...prevState,
                        twitter: true,
                      })),
                  },
                ]}
                style={{
                  width: "30px",
                  height: "30px",
                  left: 8,
                  bottom: 8,
                }}
                ref={twitterLottieRef}
              />
            </a>
            <a
              href="http://bit.ly/2kxUKbd"
              target="_blank"
              className="icon-container dribbble"
              onMouseEnter={() => handleIconMouseEnter("dribbble")}
              onMouseLeave={() => handleIconMouseLeave("dribbble")}
            >
              <Lottie
                options={dribbbleProps}
                isClickToPauseDisabled
                isStopped={stopped.dribbble}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: () =>
                      setStopped((prevState) => ({
                        ...prevState,
                        dribbble: true,
                      })),
                  },
                ]}
                style={{
                  width: "32px",
                  height: "32px",
                  left: 6,
                  bottom: 6,
                }}
                ref={dribbbleLottieRef}
              />
            </a>
            <a
              href="mailto:jonathan@jonsul.com"
              className="icon-container mail"
              onMouseEnter={() => handleIconMouseEnter("mail")}
              onMouseLeave={() => handleIconMouseLeave("mail")}
            >
              <Lottie
                options={mailProps}
                isClickToPauseDisabled
                isStopped={stopped.mail}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: () =>
                      setStopped((prevState) => ({ ...prevState, mail: true })),
                  },
                ]}
                style={{
                  width: "50px",
                  height: "50px",
                  left: 0,
                  bottom: 0,
                }}
                ref={mailLottieRef}
              />
            </a>
          </div>
          <CountdownTimer />
        </div>
      </div>
    </div>
  );
}

export default App;
